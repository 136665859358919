<template>
  <div class="resume-form-wrap">
    <div class="form-title">
      Отправить резюме
    </div>
    <!--    action="resume.php"
    method="POST"
    enctype="multipart/form-data"-->
    <form class="resume-form" id="resume-form" @submit.prevent="submitResume">
      <div class="form-control">
        <label>
          <span>Ваше имя*</span>
          <input type="text" name="name" required v-model="resumeForm.name" />
        </label>
      </div>
      <div class="form-control">
        <label>
          <span>Email*</span>
          <input
            type="email"
            name="email"
            required
            v-model="resumeForm.email"
          />
        </label>
      </div>
      <div class="form-control">
        <label>
          <span>Ваш номер телефона</span>
          <input
            type="text"
            name="phone"
            required
            v-model="resumeForm.phone"
            @keypress="validate"
            @paste="validate"
          />
        </label>
      </div>
      <div class="form-control text-center">
        <div class="current-file">
          <div class="current-file--name">
            {{ resumeForm.file ? resumeForm.file.name : "" }}
          </div>
          <button
            class="clear-file"
            type="button"
            @click="clearFileInput"
            :style="{ display: resumeForm.file ? 'inline-block' : '' }"
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.323938 11.5757L11.5742 0.325439L12.3242 1.07546L1.07396 12.3257L0.323938 11.5757Z"
                fill="black"
              />
              <path
                d="M0.854023 0.438511L12.1043 11.6888L11.3543 12.4388L0.104004 1.18853L0.854023 0.438511Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <label class="file-input" title="Разрешенные форматы .doc, .docx, .pdf">
          <span>Выбрать файл <span class="wg-attach"></span></span>
          <input
            type="file"
            ref="inputField"
            accept="application/pdf,application/msword,
                                        application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            @change="chooseFile"
            class="d-none"
            name="resume_file"
          />
        </label>
      </div>
      <button class="btn btn-gradient" type="submit">Отправить</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "ResumeForm",
  data() {
    return {
      resumeForm: {
        name: "",
        email: "",
        phone: "",
        file: null
      }
    };
  },
  methods: {
    validate(evt) {
      let theEvent = evt;

      let key;

      // Handle paste
      if (theEvent.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
      } else {
        // Handle key press
        key = theEvent.keyCode;
        key = String.fromCharCode(key);
      }
      let regex = /^[0-9+]*$/;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    clearFileInput() {
      this.$refs.inputField.value = null;
      this.resumeForm.file = null;
    },
    chooseFile(event) {
      const file = event.target.files[0];
      const ext = file.name.split(".").pop();
      if (ext === "pdf" || ext === "docx" || ext === "doc") {
        this.resumeForm.file = file;
      } else {
        alert("Разрешенные форматы .doc, .docx, .pdf");
      }
    },
    async submitResume() {
      const data = new FormData();

      if (!this.resumeForm.file) {
        return alert("Выбери файл резюме");
      }

      data.append("name", this.resumeForm.name);
      data.append("email", this.resumeForm.email);
      data.append("phone", this.resumeForm.phone);
      data.append("resume_file", this.resumeForm.file);

      try {
        const response = await fetch("/api/resume.php", {
          method: "POST",
          body: data
        });
        const result = await response.json();
        if (result.success) {
          this.emitter.emit("openModal", { type: "DialogSuccess" });

          this.resumeForm = {
            name: "",
            email: "",
            phone: "",
            file: null
          };

          this.clearFileInput();
        } else {
          this.emitter.emit("openModal", {
            type: "DialogFailed",
            retry: this.submitResume
          });
        }
      } catch (e) {
        this.emitter.emit("openModal", {
          type: "DialogFailed",
          retry: this.submitResume
        });
      }
    }
  }
};
</script>

<style scoped></style>
