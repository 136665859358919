<template>
  <main class="content-page">
    <div class="container">
      <div class="row">
        <div class="content" v-html="content"></div>
        <ResumeForm />
      </div>
    </div>
  </main>
</template>

<script>
import marked from "marked";
import ResumeForm from "@/components/ResumeForm";
export default {
  name: "Vacancy",
  components: { ResumeForm },
  data() {
    return {
      vacancy: {},
      content: ""
    };
  },
  methods: {
    async getVacancy() {
      try {
        const response = await fetch("/api/vacancies.json");
        const { vacancies } = await response.json();
        const vacancy = Object.values(vacancies)
          .filter(vacancy => vacancy.active)
          .find(vacancy => vacancy.alias === this.$route.params.slug);
        if (vacancy) {
          this.vacancy = vacancy;
          this.emitter.emit("setVacancyTitle", vacancy);
        } else {
          await this.$router.replace({ name: "NotFound" });
        }
        await this.getVacancyContent(vacancy.alias);
      } catch (e) {
        await this.$router.replace({ name: "NotFound" });
      }
    },
    async getVacancyContent(alias) {
      if (!alias) return this.$router.replace({ name: "NotFound" });
      try {
        const response = await fetch(`/api/markdown/${alias}.md`, {
          method: "GET"
        });
        let content = await response.text();
        if (content.includes("<!DOCTYPE html>")) {
          await this.$router.replace({ name: "NotFound" });
        }
        this.content = marked(content, { headerIds: false });
      } catch (e) {
        await this.$router.replace({ name: "NotFound" });
      }
    }
  },
  mounted() {
    this.getVacancy();
  }
};
</script>

<style scoped></style>
